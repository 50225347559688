import {ReCaptchaV3Service} from 'ng-recaptcha';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {httpOptionsFromParams} from '@core/commons/utils';
import {StorageKey, StorageService} from '@core/services/services/storage.service';
import {
  AuthData,
  BoolValue,
  CheckConfirmationCode,
  ConfirmationCodeResponse,
  ConfirmMobilePhone,
  recaptchaActions,
  SignInData,
  SignInDataFields,
  User,
} from '@core/types';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class AuthorizationService {
  private readonly PASSWORD_RECOVERY = '/user/restore-password';
  private readonly OAUTH = '/oauth';
  private readonly SIGN_IN_URL = `${this.OAUTH}/token`;
  private readonly REGISTRATION_URL = '/registration';
  private readonly WORKER_REGISTRATION_URL = `${this.REGISTRATION_URL}/worker`;
  private readonly LOGIN_URL = '/login';
  private readonly SEND_REGISTRATION_CONFIRM_CODE_URL = `${this.REGISTRATION_URL}/send-confirm-code-by-phone`;
  private readonly CHECK_REGISTRATION_CONFIRM_CODE_URL = `${this.REGISTRATION_URL}/check-confirm-code`;
  private readonly SEND_SIGN_IN_CONFIRM_CODE_URL = `${this.LOGIN_URL}/send-confirm-code`;
  private readonly SEND_SIGN_IN_CONFIRM_SMS_CODE_URL = `${this.LOGIN_URL}/send-confirm-sms-code`;
  private readonly CONFIRM_CODE_URL = `${this.REGISTRATION_URL}/confirming-user-sms-code`;

  private authDataSubject = new BehaviorSubject<AuthData>(null);
  public externalEntryUrl = new BehaviorSubject<string>(null);

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
  ) {
    const authData = this.storageService.getItem(StorageKey.AUTH_TOKEN);
    if (authData) {
      this.authDataSubject.next(JSON.parse(authData));
    }
  }

  private getRecaptchaToken(action: recaptchaActions): Observable<string> {
    return this.recaptchaV3Service.execute(action).pipe(filter((token) => !!token));
  }

  get authData(): Observable<AuthData> {
    return this.authDataSubject.asObservable();
  }

  isAuthorized(): Observable<boolean> {
    return this.authDataSubject.asObservable().pipe(map((data) => !!data));
  }

  handleAuthData(data: AuthData) {
    this.storageService.setItem(StorageKey.AUTH_TOKEN, JSON.stringify(data));
    this.authDataSubject.next(data);
  }

  eraseAuthData() {
    this.storageService.removeItem(StorageKey.AUTH_TOKEN);
    this.authDataSubject.next(null);
    const navigateToUrl = this.externalEntryUrl.getValue() || '/login';
    this.router.navigate([navigateToUrl]);
  }

  signIn(data: SignInData): Observable<AuthData> {
    return this.getRecaptchaToken(recaptchaActions.LOGIN).pipe(
      switchMap((recaptchaToken) =>
        this.httpClient.post<AuthData>(this.SIGN_IN_URL, {...data, recaptchaToken}),
      ),
      filter((response) => !!response),
    );
  }

  saveUserData(data: User): Observable<AuthData> {
    return this.getRecaptchaToken(recaptchaActions.REGISTRATION).pipe(
      switchMap((recaptchaToken) =>
        this.httpClient.post<AuthData>(this.REGISTRATION_URL, {...data, recaptchaToken}),
      ),
    );
  }

  saveWorker(worker: User): Observable<AuthData> {
    return this.getRecaptchaToken(recaptchaActions.REGISTRATION).pipe(
      switchMap((recaptchaToken) =>
        this.httpClient.post<AuthData>(this.WORKER_REGISTRATION_URL, {
          ...worker,
          recaptchaToken,
        }),
      ),
    );
  }

  sendRegistrationPhoneConfirmationCode(
    phone: string,
    digitalSignature = false,
  ): Observable<ConfirmationCodeResponse> {
    return this.getRecaptchaToken(recaptchaActions.SEND_CONFIRM_CODE).pipe(
      switchMap((recaptchaToken) =>
        this.httpClient.post<ConfirmationCodeResponse>(this.SEND_REGISTRATION_CONFIRM_CODE_URL, {
          phone,
          recaptchaToken,
          digitalSignature,
        }),
      ),
    );
  }

  checkRegistrationPhoneConfirmationCode(data: CheckConfirmationCode): Observable<BoolValue> {
    return this.getRecaptchaToken(recaptchaActions.APPROVE_CONFIRM_CODE).pipe(
      switchMap((recaptchaToken) =>
        this.httpClient.post<BoolValue>(this.CHECK_REGISTRATION_CONFIRM_CODE_URL, {
          ...data,
          recaptchaToken,
        }),
      ),
    );
  }

  passwordRecoveryByPhoneNumber(phone: string): Observable<BoolValue> {
    return this.httpClient.put<BoolValue>(this.PASSWORD_RECOVERY, {phone});
  }

  sendSignInPhoneConfirmationCode(phone: string): Observable<ConfirmationCodeResponse> {
    return this.getRecaptchaToken(recaptchaActions.SEND_CONFIRM_CODE).pipe(
      switchMap((recaptchaToken) =>
        this.httpClient.post<ConfirmationCodeResponse>(this.SEND_SIGN_IN_CONFIRM_CODE_URL, {
          phone,
          recaptchaToken,
        }),
      ),
    );
  }

  sendSignInPhoneSmsConfirmationCode(phone: string): Observable<ConfirmationCodeResponse> {
    return this.getRecaptchaToken(recaptchaActions.SEND_CONFIRM_CODE).pipe(
      switchMap((recaptchaToken) =>
        this.httpClient.post<ConfirmationCodeResponse>(this.SEND_SIGN_IN_CONFIRM_SMS_CODE_URL, {
          phone,
          recaptchaToken,
        }),
      ),
    );
  }

  confirmRegistrationUserPhone(data: ConfirmMobilePhone): Observable<BoolValue> {
    return this.httpClient.put<BoolValue>(this.CONFIRM_CODE_URL, null, httpOptionsFromParams(data));
  }

  refreshAuthData(): Observable<AuthData> {
    return this.authData.pipe(
      take(1),
      switchMap((authData) =>
        this.signIn({
          [SignInDataFields.GRANT_TYPE]: 'refresh_token',
          [SignInDataFields.CLIENT_ID]: environment.clientId,
          [SignInDataFields.CLIENT_CLASSIFIER]: environment.clientClassifier,
          [SignInDataFields.REFRESH_TOKEN]: authData.refresh_token,
        }),
      ),
    );
  }
}
