import {
  CertificationCenter,
  EuSignCpService,
  ScriptGroupsName,
  UserCertificateInfo,
} from '@innovations28/e-sign';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FileInput} from 'ngx-material-file-input';
import {Observable} from 'rxjs';
import {map, startWith, switchMap, tap} from 'rxjs/operators';

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

import {buildFileESignForm} from '@core/commons/forms';
import {BlockUntilResponseService} from '@core/services/services/local/block-until-response.service';
import {NotificationService} from '@core/services/services/notification.service';
import {FileESignFormFields} from '@core/types';

import {ESignInfoModalComponent} from '../e-sign-info-modal/e-sign-info-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-file-e-sign',
  templateUrl: './file-e-sign.component.html',
  styleUrls: ['./file-e-sign.component.scss'],
})
export class FileESignComponent implements OnInit {
  @Output() userCertificateInfo = new EventEmitter<UserCertificateInfo>();

  cas$: Observable<CertificationCenter[]>;
  form: FormGroup;
  formFields = FileESignFormFields;

  isLoading = this.blockUntilResponseService.blockState$.pipe(
    tap((isLoading) => this.form[isLoading ? 'disable' : 'enable']({emitEvent: false})),
  );

  constructor(
    private formBuilder: FormBuilder,
    private euSignCpService: EuSignCpService,
    private notificationService: NotificationService,
    public blockUntilResponseService: BlockUntilResponseService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.form = buildFileESignForm(this.formBuilder);

    this.cas$ = this.euSignCpService.getAvailableCAs().pipe(
      switchMap((cas) => {
        return this.certificateAuthorityControl.valueChanges.pipe(
          startWith(''),
          map((caName) => (typeof caName === 'string' ? caName : caName.issuerCNs[0])),
          map((caName) => (caName ? this.filterCasArray(cas, caName) : cas)),
        );
      }),
    );

    this.euSignCpService
      .initByScriptGroup(ScriptGroupsName.JS_EU_SIGN_LIB)
      .pipe(this.blockUntilResponseService.execute)
      .subscribe(
        () => {},
        (error) => {
          this.notificationService.notify(error);
          console.log('error in root');
          console.log(error);
        },
      );
  }

  private filterCasArray(array: CertificationCenter[], name: string): CertificationCenter[] {
    return array.filter(({issuerCNs}) => issuerCNs[0].toLowerCase().includes(name.toLowerCase()));
  }

  displayFn(entity: CertificationCenter): string {
    return entity?.issuerCNs?.length ? entity.issuerCNs[0] : '';
  }

  get selectedFiles(): File[] {
    return (this.form.get(FileESignFormFields.E_SIGN_FILE).value as FileInput).files;
  }

  get certificatePassword(): string {
    return this.form.get(FileESignFormFields.PASSWORD).value;
  }

  get certificateAuthorityControl(): FormControl {
    return this.form.get(FileESignFormFields.CERTIFICATE_AUTHORITY) as FormControl;
  }

  get certificateAuthority(): CertificationCenter {
    return this.form.get(FileESignFormFields.CERTIFICATE_AUTHORITY).value;
  }

  loadCertificateAndSignIn() {
    this.euSignCpService
      .getCertificateInfoByFile(
        this.selectedFiles[0],
        this.certificatePassword,
        this.certificateAuthority,
      )
      .pipe(untilDestroyed(this), this.blockUntilResponseService.execute)
      .subscribe(
        (result: UserCertificateInfo) => {
          this.notificationService.notify('Ключ успішно завантаженно');
          this.userCertificateInfo.emit(result);
        },
        (error) => this.notificationService.notify(error),
      );
  }

  openModalWithInstructionsOfGettingEcp(): void {
    this.dialog.open(ESignInfoModalComponent, {
      width: '1150px',
    });
  }
}
