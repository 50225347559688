import * as moment from 'moment';

export enum ApiResponseStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  AUTH_ERROR = 'AUTH_ERROR',
  EMPTY_RESULT = 'EMPTY_RESULT',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  BAD_CREDENTIALS = 'BAD_CREDENTIALS',
}

export interface ApiResponse<T> {
  status: ApiResponseStatus;
  userMessage: string;
  message: string;
  body: T;
}

export interface BoolValue {
  value: boolean;
}

export interface PasswordRecovery {
  phoneNumber: string;
}

export enum RegistrationFields {
  ID = 'id',
  INN = 'inn',
  CERTIFICATE_SERIAL = 'serialEDS',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MIDDLE_NAME = 'middleName',
  EMAIL = 'email',
  PASSWORD = 'password',
  MOBILE_PHONE = 'phone',
  CODE = 'code',
  CLIENT_ID = 'clientId',
  CLIENT_CLASSIFIER = 'clientClassifier',
  UUID = 'uuid',
  CONFIRM_PASSWORD = 'confirmPassword',
  PASSPORT_SERIAL = 'passCode',
  PASSPORT_NUMBER = 'passNumber',
  PASSPORT_ISSUER = 'passIssuer',
  BIRTH_DATE = 'birthDate',
  AUTH_ID = 'authId',
  USER_NAME = 'username',
  USER_REG_DATA = 'userRegData',
  USER_TYPE = 'userType',
}

export interface UserRegistrationForm {
  [RegistrationFields.INN]: number;
  [RegistrationFields.CERTIFICATE_SERIAL]: string;
  [RegistrationFields.PASSPORT_SERIAL]: string;
  [RegistrationFields.PASSPORT_NUMBER]: string;
  [RegistrationFields.PASSPORT_ISSUER]: string;
  [RegistrationFields.PASSWORD]: string;
  [RegistrationFields.FIRST_NAME]: string;
  [RegistrationFields.LAST_NAME]: string;
  [RegistrationFields.MIDDLE_NAME]: string;
  [RegistrationFields.MOBILE_PHONE]: string;
  [RegistrationFields.EMAIL]: string;
  [RegistrationFields.CODE]: string;
  [RegistrationFields.BIRTH_DATE]: moment.Moment;
  [RegistrationFields.CLIENT_ID]: string;
  [RegistrationFields.CLIENT_CLASSIFIER]: string;
  [RegistrationFields.UUID]: string;
}

export interface ID {
  id: string;
}

export class User implements ID {
  [RegistrationFields.ID]: string;
  [RegistrationFields.PASSWORD]: string;
  [RegistrationFields.FIRST_NAME]: string;
  [RegistrationFields.LAST_NAME]: string;
  [RegistrationFields.MIDDLE_NAME]: string;
  [RegistrationFields.MOBILE_PHONE]: string;
  [RegistrationFields.EMAIL]: string;
  [RegistrationFields.BIRTH_DATE]?: moment.Moment;
  [RegistrationFields.PASSPORT_SERIAL]?: string;
  [RegistrationFields.PASSPORT_NUMBER]?: string;
  [RegistrationFields.PASSPORT_ISSUER]?: string;
  [RegistrationFields.INN]?: number;
  [RegistrationFields.CERTIFICATE_SERIAL]?: string;
  [RegistrationFields.AUTH_ID]?: string;
  [RegistrationFields.USER_NAME]?: string;

  constructor(data: any = {}) {
    this[RegistrationFields.ID] = data.id;
    this[RegistrationFields.PASSWORD] = data[RegistrationFields.PASSWORD];
    this[RegistrationFields.FIRST_NAME] = data[RegistrationFields.FIRST_NAME];
    this[RegistrationFields.LAST_NAME] = data[RegistrationFields.LAST_NAME];
    this[RegistrationFields.MIDDLE_NAME] = data[RegistrationFields.MIDDLE_NAME];
    this[RegistrationFields.MOBILE_PHONE] = data[RegistrationFields.MOBILE_PHONE];
    this[RegistrationFields.EMAIL] = data[RegistrationFields.EMAIL];
    this[RegistrationFields.BIRTH_DATE] = moment.utc(data[RegistrationFields.BIRTH_DATE]);
    this[RegistrationFields.PASSPORT_SERIAL] = data[RegistrationFields.PASSPORT_SERIAL];
    this[RegistrationFields.PASSPORT_NUMBER] = data[RegistrationFields.PASSPORT_NUMBER];
    this[RegistrationFields.PASSPORT_ISSUER] = data[RegistrationFields.PASSPORT_ISSUER];
    this[RegistrationFields.INN] = data[RegistrationFields.INN];
    this[RegistrationFields.CERTIFICATE_SERIAL] = data[RegistrationFields.CERTIFICATE_SERIAL];
    this[RegistrationFields.AUTH_ID] = data.authId;
    this[RegistrationFields.USER_NAME] = data.username;
  }
}

export enum FileESignFormFields {
  CERTIFICATE_AUTHORITY = 'certificateAuthority',
  E_SIGN_FILE = 'eSignFile',
  PASSWORD = 'password',
}

export enum HardwareESignFormFields {
  MEDIA_TYPE = 'mediaType',
  MEDIA_DEVICE = 'mediaDevice',
  CERTIFICATE_AUTHORITY = 'certificateAuthority',
  PASSWORD = 'password',
}

export enum AuthType {
  EDS = 'EDS',
  PHONE = 'PHONE',
}

export enum SignInDataFields {
  AUTH_TYPE = 'authType',
  GRANT_TYPE = 'grantType',
  CLIENT_ID = 'clientId',
  CLIENT_CLASSIFIER = 'clientClassifier',
  INN = 'inn',
  PASSWORD = 'password',
  UUID = 'uuid',
  CODE = 'code',
  REFRESH_TOKEN = 'refreshToken',
}

export interface SignInData {
  [SignInDataFields.AUTH_TYPE]?: AuthType;
  [SignInDataFields.GRANT_TYPE]: string;
  [SignInDataFields.CLIENT_ID]: string;
  [SignInDataFields.CLIENT_CLASSIFIER]: string;
  [SignInDataFields.INN]?: number;
  [SignInDataFields.PASSWORD]?: string;
  [SignInDataFields.UUID]?: string;
  [SignInDataFields.CODE]?: string;
  [SignInDataFields.REFRESH_TOKEN]?: string;
}

export interface ExternalLoginRouteData {
  callback: string;
  clientId: string;
  clientClassifier: string;
  withESign?: boolean;
}

export interface AuthData {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;
}

export enum ConfirmMobilePhoneFields {
  ID = 'id',
  USER_CODE = 'userCode',
}

export interface ConfirmMobilePhone extends ID {
  [ConfirmMobilePhoneFields.USER_CODE]: string;
}

export enum ConfirmationCodeState {
  SENT = 'SENT',
  USER_EXISTS = 'USER_EXISTS',
}

export interface ConfirmationCodeResponse {
  uuid: string;
  codeLength: number;
  codeLiveTimeMinutes: number;
  maxAttempts: number;
  state: ConfirmationCodeState;
  message: string;
}

export interface CheckConfirmationCode {
  uuid: string;
  code: string;
}

export interface IdName {
  id: number;
  name: string;
}

export interface UserAuthParams {
  callback: string;
  clientId: string;
  clientClassifier: string;
  userClassifier: UserClassifier;
  entryUrl?: string;
}

export enum GovAuthType {
  MOBILE_ID = 'mobile_id',
  BANK_ID = 'bank_id',
}

export enum UserClassifier {
  CRM_WORKER = 'CRM_WORKER',
  SCHOOL_WORKER = 'SCHOOL_WORKER',
  RTG_WORKER = 'RTG_WORKER',
  CABINET_USER = 'CABINET_USER',
}

export enum recaptchaActions {
  SEND_CONFIRM_CODE = 'SEND_CONFIRM_CODE',
  APPROVE_CONFIRM_CODE = 'APPROVE_CONFIRM_CODE',
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
}

export enum EnvironmentType {
  ISO_PREPROD = 'ISO_PREPROD',
  ISO_PROD = 'ISO_PROD',
  ISO_PROD_DNEPR = 'ISO_PROD_DNEPR',
  ISO_SANDBOX = 'ISO_SANDBOX',
  ISO_STAGE = 'ISO_STAGE',
  PREPROD = 'PREPROD',
  PROD = 'PROD',
  PROD_02 = 'PROD_02',
  PROD_DNEPR = 'PROD_DNEPR',
  SANDBOX = 'SANDBOX',
  SANDBOX_2 = 'SANDBOX_2',
  STAGE = 'STAGE',
  LOCAL = 'LOCAL',
  UAT = 'UAT',
  KHR_AWS_PROD = 'KHR_AWS_PROD',
}

export enum UserRegDataFields {
  SCHOOL_ID = 'SCHOOL_ID',
  CLASS_IDS = 'CLASS_IDS',
  MANAGEMENT_ID = 'MANAGEMENT_ID',
  DEPARTMENT_ID = 'DEPARTMENT_ID',
  POSITION_ID = 'POSITION_ID',
}

export enum UserTypeValues {
  SCHOOL_WORKER = 'SCHOOL_WORKER',
  RTG_WORKER = 'RTG_WORKER',
}
