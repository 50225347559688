import {EnvironmentType} from '@core/types';

const backEndApiUrl = 'https://uat-auth-api.globalpoll.com.ua';
const apiGatewayUrl = 'https://uat-api-gateway.globalpoll.com.ua';
export const environment = {
  production: false,
  euSignCpProxyServerUrl: 'https://proxykey.isolutions.io/ProxyHandler.php',
  backEndApiUrl: `${apiGatewayUrl}/auth-service`,
  clientId: 'client',
  cabinetClientId: 'c4b15fa67e014a82ab87a6625dbe075f_poll',
  clientClassifier: '123456',
  crmApiUrl: 'https://uat-api.globalpoll.com.ua',
  recaptchaSiteKey: '6Le2u88ZAAAAAC7pAb_Cb5T7xpLkJzCS_M2xFHyq',
  govSignUrl: `${apiGatewayUrl}/auth-service/signin/gov`,
  type: EnvironmentType.UAT,
};
