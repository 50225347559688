import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {addAuthTokenToRequest, isNullOrUndefined} from '@core/commons/utils';
import {AuthorizationService} from '@core/services/services/authorization.service';
import {NotificationService} from '@core/services/services/notification.service';
import {ApiResponse, ApiResponseStatus} from '@core/types';
import {environment} from '@environments/environment';

@Injectable()
export class ApiResponseHandlerInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private authorizationService: AuthorizationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap((r) => {
        if (r instanceof HttpResponse) {
          const statusOk = r.status >= 200 && r.status < 400;
          if (statusOk) {
            const body = r.body as ApiResponse<any>;
            if (!isNullOrUndefined(body) && !isNullOrUndefined(body.status)) {
              const apiStatus = body.status;
              switch (apiStatus) {
                case ApiResponseStatus.OK:
                  return of(r);
                case ApiResponseStatus.AUTH_ERROR:
                  this.notifyAboutError(body);
                  // this.authorizationService.eraseAuthData();
                  return this.returnEmptyApiBody(r);
                case ApiResponseStatus.BAD_CREDENTIALS:
                  this.notifyAboutError(body);
                  return this.returnEmptyApiBody(r);
                case ApiResponseStatus.TOKEN_EXPIRED:
                  return this.authorizationService.refreshAuthData().pipe(
                    switchMap((authData) => {
                      this.authorizationService.handleAuthData(authData);
                      return addAuthTokenToRequest(req, this.authorizationService).pipe(
                        switchMap((authReq) => next.handle(authReq)),
                      );
                    }),
                  );
                case ApiResponseStatus.EMPTY_RESULT:
                  this.notificationService.notify(body.message);
                  return this.returnEmptyApiBody(r);
                case ApiResponseStatus.ERROR:
                  if (!environment.production) {
                    console.log('Error api response:', body);
                  }
                  this.notifyAboutError(body);
                  return this.returnEmptyApiBody(r);
                default:
                  this.notificationService.notify(body.message);
                  return of(r);
              }
            }
          }
        }
        return of(r);
      }),
      tap(
        (event) => {},
        (error: HttpErrorResponse) => {
          this.notificationService.notify('Внутрішня помилка сервера');
        },
      ),
    );
  }

  private returnEmptyApiBody(r: HttpResponse<any>): Observable<HttpResponse<any>> {
    return of(
      r.clone<any>({
        body: null,
      }),
    );
  }

  private notifyAboutError(resp: ApiResponse<any>) {
    const message = resp.userMessage || resp.message;
    this.notificationService.notify(message || 'Невідома помілка');
  }
}
