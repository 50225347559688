import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {HttpParams, HttpRequest} from '@angular/common/http';
import {Params} from '@angular/router';

import {AUTH_HEADER} from '@core/commons/constants';
import {AuthorizationService} from '@core/services/services/authorization.service';
import {IdName, UserAuthParams, UserClassifier} from '@core/types';

export const buildQueryParamsString = (data: any): string =>
  Object.entries(data)
    .reduce((previousValue, currentValue) => [...previousValue, reducePaid(currentValue)], [])
    .join('&');

export const reducePaid = (pair: [string, any]): string =>
  `${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`;

export const isNullOrUndefined = (value: any): boolean => value === null || value === undefined;

export const httpOptionsFromParams = (params?: any) => {
  if (!params) {
    return;
  }

  return {
    params: new HttpParams({
      fromObject: {
        ...params,
      },
    }),
  };
};

export const generateRedirectUrl = (callback: string, queryParams: any) =>
  callback.includes('?')
    ? `${callback}&${buildQueryParamsString(queryParams)}`
    : `${callback}?${buildQueryParamsString(queryParams)}`;

export const addAuthTokenToRequest = (
  req: HttpRequest<any>,
  authService: AuthorizationService,
): Observable<HttpRequest<any>> =>
  authService.authData.pipe(
    take(1),
    map((authData) => {
      let request = req;
      if (authData) {
        request = req.clone({
          headers: req.headers.append(
            AUTH_HEADER,
            `${authData.token_type} ${authData.access_token}`,
          ),
        });
      }
      return request;
    }),
  );

export const newObj = (Class) => (data) => new Class(data);

export const authDataFromParams = (params: Params): UserAuthParams => ({
  clientId: params.clientId,
  clientClassifier: params.clientClassifier,
  callback: params.callback,
  userClassifier: params.userClassifier ?? UserClassifier.CRM_WORKER,
  entryUrl: params.entryUrl,
});

export const isObjectType = (data: unknown): boolean => {
  return (
    !!data &&
    data === Object(data) &&
    Object.prototype.toString.call(data) !== '[object Array]' &&
    Object.prototype.toString.call(data) !== '[object Function]'
  );
};

export const idNameSortFn = (a: IdName, b: IdName) => a.name?.localeCompare(b.name);

export const filterIdNameArray = (array: IdName[], name: string): IdName[] =>
  array.filter((entity) => entity.name?.toLowerCase().includes(String(name).toLowerCase()));
